import {Component, ElementRef, OnInit, Optional, Renderer2, ViewChild} from '@angular/core';
import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '../../layout.service';
import {TRANSLATION_LAYOUT_SCOPE, TRANSLATION_MENU_SCOPE, TRANSLATION_USER_MENU_SCOPE} from '../../../constants';

// hack to inject decorator declarations. must occur before class declaration!
export interface LayoutComponent extends Translatable {}

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css'],
  providers: [
    {provide: 'TRANSLATION_SCOPE', useValue: TRANSLATION_LAYOUT_SCOPE},
  ]
})
@Translatable({scope: TRANSLATION_LAYOUT_SCOPE, keys: {}})
export class LayoutComponent implements OnInit {
  /**
   * Menu wrapper element
   */
  @ViewChild('menuWrapper') menuWrapper: ElementRef;

  /**
   * Software version
   * @type {string}
   */
  public version = '0.0.0';

  /**
   * Software version link
   * @type {string}
   */
  public versionLink;

  /**
   * Menu for sidepanel and main menu
   * @type {Array}
   */
  public menu: any[] = [];

  /**
   * User menu for user box
   * @type {Array}
   */
  public userMenu: any[] = [];

  /**
   * User object to render top right panel
   * @type {{}}
   */
  public user: {username?: string, image?: string} = {};

  /**
   * Header page title
   */
  public pageTitle: string;

  /**
   * Header page logo
   */
  public pageLogo: string;

  /**
   * Preloader label
   */
  public preloaderLabel = 'Bitte warten...';

  /**
   * Active menu entry
   * @type {any}
   */
  public activeMenuEntry: any;

  /**
   * Should the menu be visible
   * @type {boolean}
   */
  public isMenuVisible = false;

  /**
   * Is the locale module available?
   * @type {boolean}
   */
  public readonly hasLocaleSupport: boolean = false;

  /**
   * Element reference to preloader
   */
  @ViewChild('layoutPreloader') private preloaderRef: ElementRef;

  /**
   * Injects dependencies
   */
  constructor(
    private layoutService: LayoutService,
    private renderer: Renderer2,
    @Optional() private translationService: TranslationService,
    @Optional() private translationEvents: TranslationEventService
  ) {
    this.hasLocaleSupport = !!this.translationService;

    const updatePreloaderLabel = () => this.translationService && (this.preloaderLabel = this.translationService
      .translate({text: this.preloaderLabel, scope: TRANSLATION_LAYOUT_SCOPE}));

    updatePreloaderLabel();
    this.translationEvents && this.translationEvents.onLocaleChanged().subscribe(() => updatePreloaderLabel());
  }

  /**
   * On init event hook
   */
  ngOnInit() {
    this.layoutService.subscribeToDisplayMenu(() => {
      this.isMenuVisible = true;
    });

    this.layoutService.subscribeToHideMenu(() => {
      setTimeout(() => {
        this.toggleMenu(null, this.menuWrapper.nativeElement);
        this.isMenuVisible = false;
      }, 600);
    })

    this.layoutService.subscribeToMenuChanged((menu) => {
      this.versionLink = this.layoutService.getVersionLink()
      this.menu = menu;
    });

    this.layoutService.subscribeToUserChanged((user) => {
      this.user = user;
    });

    this.layoutService.subscribeToUserMenuChanged((userMenu) => {
      this.userMenu = userMenu;
    });

    this.layoutService.subscribeToActiveMenuEntryChanged((activeMenuEntry) => {
      this.activeMenuEntry = activeMenuEntry;
    });

    this.layoutService.subscribeToPageLogoChanged((pageLogo) => {
      this.pageLogo = pageLogo;
    });

    this.layoutService.subscribeToPageTitleChanged((pageTitle) => {
      this.pageTitle = pageTitle;
    });

    this.layoutService.subscribeToPreloaderChanged((settings: {show: boolean, label?: string}) => {
      if (settings.show === true) {
        this.addClasses(this.preloaderRef, ['visible']);
      }

      if (settings.show === false) {
        this.removeClasses(this.preloaderRef, ['visible']);
      }

      this.preloaderLabel = 'Bitte warten...';

      if (settings.label) {
        this.preloaderLabel = settings.label;
      }
    });

    // set vars by layout service
    this.version = this.layoutService.getVersion();
    this.userMenu = this.layoutService.getUserMenu();
    this.menu = this.layoutService.getMenu();
    this.user = this.layoutService.getUser();
    this.pageTitle = this.layoutService.getPageTitle();
    this.pageLogo = this.layoutService.getPageLogo();
  }

  /**
   * Toggles the menu
   */
  public toggleMenu(event, el) {
    if (el.classList.contains('toggled')) {
      this.renderer.removeClass(el, 'toggled');
    } else {
      this.renderer.addClass(el, 'toggled');
    }
  }

  /**
   * Toggles the "open" class
   */
  public toggleOpenClass(event, el) {
    if (el.classList.contains('open')) {
      this.renderer.removeClass(el, 'open');
    } else {
      this.renderer.addClass(el, 'open');
    }
  }

  /**
   * Removes the "open" class
   */
  public removeOpenClass(event, el) {
    this.renderer.removeClass(el, 'open');
  }

  public routeHome() {
    location.href = '/';
  }

  /**z
   * Translates breadcrumb entry
   *
   * @param {string} text
   * @returns {string}
   */
  public translateBreadcrumbEntry(entry: {name: string, parent: {name: string}}): string {
    let text;
    let subText;

    text = entry.name;

    if (entry.parent && entry.parent.name) {
      subText = entry.name;
      text = entry.parent.name;
    }

    return this.translateMenuEntry(text, subText, TRANSLATION_MENU_SCOPE);
  }

  /**
   * Translates main menu entry
   *
   * @param {string} text
   * @returns {string}
   */
  public translateMainMenuEntry(text: string, subText: string = ''): string {
    return this.translateMenuEntry(text, subText, TRANSLATION_MENU_SCOPE);
  }

  /**
   * Translates user menu entry
   *
   * @param {string} text
   * @returns {string}
   */
  public translateUserMenuEntry(text: string, subText: string = ''): string {
    return this.translateMenuEntry(text, subText, TRANSLATION_USER_MENU_SCOPE);
  }

  /**
   * Translates menu entry
   * @param {string} text
   * @param {string} subText
   * @param {string} scope
   * @returns {string}
   */
  private translateMenuEntry(text: string, subText: string = '', scope: string[]): string {
    if (!text || !this.translationService) {
      return subText ? subText : text;
    }

    const mixedText = subText ? `${text}.${subText}` : text;
    const translation = this.translationService.translate({text: mixedText, scope});
    return (subText && translation === mixedText) ? subText : translation;
  }

  /**
   * Removes given css classes from given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private removeClasses(el: ElementRef, classes: any[]) {
    for (const cls of classes) {
      this.renderer.removeClass(el.nativeElement, cls);
    }
  }

  /**
   * Adds given css classes to given element ref
   *
   * @param {ElementRef} el
   * @param {any[]} classes
   */
  private addClasses(el: ElementRef, classes: any[]) {
    for (const cls of classes) {
      this.renderer.addClass(el.nativeElement, cls);
    }
  }
}
