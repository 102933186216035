import {Component, OnInit, Optional, ViewChild} from '@angular/core';
import {NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';

import {Translatable, TranslationEventService, TranslationService} from '@ngmedax/translation';
import {LayoutService} from '@ngmedax/layout';

import {UserTableExportService} from '../../services/user-table-export.service';
import {TRANSLATION_TABLE_EXPORT_SCOPE} from '../../../../constants';
import {UserService} from '../../services/user.service';
import {KEYS} from '../../../../translation-keys';
import {UserQuery} from '../../../../types';


// hack to inject decorator declarations. must occur before class declaration!
export interface UserTableExportComponent extends Translatable {}

@Component({
  selector: 'app-user-table-export',
  templateUrl: './user-table-export.component.html',
  styleUrls: ['./user-table-export.component.css'],
  providers: [
    {provide: 'TRANSLATION_SCOPE', useValue: TRANSLATION_TABLE_EXPORT_SCOPE},
  ]
})
@Translatable({scope: TRANSLATION_TABLE_EXPORT_SCOPE, keys: KEYS})
export class UserTableExportComponent implements OnInit {
  public isTableSearchCollapsed = true;
  public toDateMin: any = {};
  public exportCount = 0;

  @ViewChild('fromDate') fromDateRef: NgbInputDatepicker;
  @ViewChild('toDate') toDateRef: NgbInputDatepicker;

  public set selectedFromDate(value: NgbDateStruct) {
    this.toDateMin = value;
    delete(this.selectedToDate);
    this._selectedFromDate = value;
    this.updateExportCount();
  }

  public get selectedFromDate(): NgbDateStruct {
    return this._selectedFromDate;
  }

  public set selectedToDate(value: NgbDateStruct) {
    this._selectedToDate = value;
    this.updateExportCount();
  }

  public get selectedToDate(): NgbDateStruct {
    return this._selectedToDate;
  }

  /**
   * Hidden member for selected "from date"
   */
  private _selectedFromDate: any;

  /**
   * Hidden member for selected "to date"
   */
  private _selectedToDate: any;

  /**
   * Default locale
   */
  public locale = 'de_DE';

  /**
   * Inject dependencies
   */
  public constructor(
    private layout: LayoutService,
    private userService: UserService,
    private userTableExportService: UserTableExportService,
    @Optional() private translationService: TranslationService,
    @Optional() private translationEvents: TranslationEventService
  ) {
  }

  /**
   * Initialize date pickers with localized date values
   */
  public async ngOnInit() {

    // auto translates selected from/to date on locale changed event
    this.translationEvents && this.translationEvents.onLocaleChanged().subscribe(() => {
      if (!this.selectedFromDate || !this.selectedToDate || !this.fromDateRef || !this.toDateRef) {
        return;
      }

      const fromDate = this.toDate(this.selectedFromDate);
      const toDate = this.toDate(this.selectedToDate);
      this.fromDateRef.writeValue({year: fromDate.getFullYear(), month: fromDate.getMonth() + 1, day: fromDate.getDate()});
      this.toDateRef.writeValue({year: toDate.getFullYear(), month: toDate.getMonth() + 1, day: toDate.getDate()});
    });
  }

  /**
   * Returns true if we can export (all mandatory fields set)
   *
   * @return {boolean}
   */
  public canExport(): boolean {
    return !!this.selectedFromDate && !!this.selectedToDate;
  }

  /**
   * Event handler for when export button was clicked
   */
  public async onExport() {
    const fromDate = this.toDate(this.selectedFromDate).toJSON();
    const toDate = this.toDate(this.selectedToDate, 23, 59, 59).toJSON();

    this.layout.showPreloader();
    const query = this.getTableExportQuery();
    const users = await this.userService.getByFilter(query).then(result => result.rows);
    this.userTableExportService.export(users);
    this.layout.hidePreloader();
  }

  /**
   * Updates estimated export count by form values
   */
  private updateExportCount() {
    if (!this.canExport()) {
      this.exportCount = 0;
      return;
    }

    const update = async () => {
      const query = this.getTableExportQuery();
      this.exportCount = await this.userService.getCountByFilter(query);
    };

    update().catch(error => console.error(error));
  }

  /**
   * Returns table export query by form values
   *
   * @returns {UserQuery}
   */
  private getTableExportQuery(): UserQuery {
    const fromDate = this.toMysqlDate(this.selectedFromDate);
    const toDate = this.toMysqlDate(this.selectedToDate, 23, 59, 59);
    return {where: {createdAt: {'$between': [fromDate, toDate]}}};
  }

  /**
   * Returns date object by given ngb date object
   *
   * @param date
   */
  private toDate(date: NgbDateStruct, hours = 0, minutes = 0, seconds = 0): Date {
    const year = date.year;
    const month = this.pad(date.month);
    const day = this.pad(date.day);

    const jsonDate = `${year}-${month}-${day}T${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}.000Z`;
    return new Date(jsonDate);
  }

  /**
   * Returns a MySQL date string by given ngb date object
   *
   * @param date NgbDateStruct
   * @param hours number (optional)
   * @param minutes number (optional)
   * @param seconds number (optional)
   * @returns string - Date in MySQL format ('YYYY-MM-DD' or 'YYYY-MM-DD HH:MM:SS')
   */
  private toMysqlDate(date: NgbDateStruct, hours?: number, minutes?: number, seconds?: number): string {
    const year = date.year;
    const month = this.pad(date.month);
    const day = this.pad(date.day);

    // If time is provided, format as 'YYYY-MM-DD HH:MM:SS', else just 'YYYY-MM-DD'
    if (hours !== undefined && minutes !== undefined && seconds !== undefined) {
      return `${year}-${month}-${day} ${this.pad(hours)}:${this.pad(minutes)}:${this.pad(seconds)}`;
    } else {
      return `${year}-${month}-${day}`;
    }
  }

  /**
   * Adds zero padding
   *
   * @param {number} value
   * @returns string
   */
  private pad(value: number): string {
    return `0${value}`.slice(-2);
  }
}
