import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {LayoutService} from '@ngmedax/layout';

import {NAVIGATION} from './ums.navigation';
import {UmsService} from './services/ums.service';
import {AuthTokenService} from './services/auth-token.service';
import {AuthUriService} from './services/auth-uri.service';
import {PasswordService} from './services/password.service';
import {UserService} from './services/user.service';
import {UserTableExportService} from './services/user-table-export.service';
import {UserGridComponent} from './components/user-grid/user-grid.component';
import {UserCrudComponent} from './components/user-crud/user-crud.component';
import {UserChangeComponent} from './components/user-change/user-change.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';
import {UserFilterGroupComponent} from './components/user-filter-group/user-filter-group.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {UserPermissionComponent} from './components/user-permission/user-permission.component';
import {UserTableExportComponent} from './components/user-table-export/user-table-export.component';
import {AuthTokenComponent} from './components/auth-token/auth-token.component';
import {NgbDateDeParserFormatter} from './date-parser/ngb-date-de-parser-formatter';
import {TruncatePipe} from './pipes/truncate.pipe';
import {DatexPipe} from './pipes/datex.pipe';
import {routing} from './ums.routing';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    routing
  ],
  declarations: [
    UserGridComponent,
    UserCrudComponent,
    UserChangeComponent,
    ChangePasswordComponent,
    UserPermissionComponent,
    UserFilterGroupComponent,
    ResetPasswordComponent,
    AuthTokenComponent,
    UserTableExportComponent,
    DatexPipe,
    TruncatePipe
  ],
  exports: [],
  providers: [
    UmsService,
    UserService,
    AuthTokenService,
    AuthUriService,
    PasswordService,
    UserTableExportService,
    {provide: NgbDateParserFormatter, useClass: NgbDateDeParserFormatter}
  ]
})
export class UmsModule {

  constructor(private layoutService: LayoutService) {
    layoutService.addMenuEntry(NAVIGATION);

    // get user menu from layout service
    const userMenu: any[] = layoutService.getUserMenu();

    // add "my profile" as first entry of user menu
    if (userMenu) {
      userMenu.unshift({
        name: 'Mein Profil',
        path: `/module/ums/user/change`,
        icon: 'fa-user'
      });
    }
  }
}
