export const configKeys = {
  /**
   * Config key for filter group uri
   * @type {string}
   */
  FILTER_GROUP_URI_CONFIG_KEY: 'apis.filtergroup.uri',

  /**
   * Config key for tenant uri
   * @type {string}
   */
  TENANT_URI_CONFIG_KEY: 'apis.ums.resources.tenant',

  /**
   * Config key for role uri
   * @type {string}
   */
  ROLE_URI_CONFIG_KEY: 'apis.ums.resources.role',

  /**
   * Config key for user uri
   * @type {string}
   */
  USER_URI_CONFIG_KEY: 'apis.ums.resources.user',

  /**
   * Config key for user reset pw uri
   * @type {string}
   */
  USER_RESET_PW_URI_CONFIG_KEY: 'apis.ums.resources.resetPasswordInit',

  /**
   * Config key for auth token validate uri
   * @type {string}
   */
  AUTH_TOKEN_VALIDATE_KEY: 'apis.ums.resources.authTokenValidate',

  /**
   * Config key for reset password token validate uri
   * @type {string}
   */
  RESET_PASSWORD_TOKEN_VALIDATE: 'apis.ums.resources.resetPasswordTokenValidate',

  /**
   * Config key for reset password captcha uri
   * @type {string}
   */
  RESET_PASSWORD_CAPTCHA_KEY: 'apis.ums.resources.resetPasswordCaptcha',

  /**
   * Config key for reset password init uri
   * @type {string}
   */
  RESET_PASSWORD_INIT_KEY: 'apis.ums.resources.resetPasswordInit',

  /**
   * Config key for ums base uri
   * @type {string}
   */
  BASE_URI_CONFIG_KEY: 'apis.ums.uri'
};
