import {Component, EventEmitter, Input, OnInit, Optional, Output} from '@angular/core';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {PasswordPolicy, User} from '../../../../types';
import {TimeoutService} from '../../services/timeout.service';
import {UserService} from '../../services/user.service';
import {PasswordService} from '../../services/password.service';
import {RegistryService} from '@ngmedax/registry';
import {LayoutService} from '@ngmedax/layout';
import {LoginService} from '@ngmedax/login';
import {Router} from '@angular/router';
import {passwordValidators as validators} from './password.validators';
import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';


// hack to inject decorator declarations. must occur before class declaration!
export interface ChangePasswordComponent extends Translatable {}

/**
 * Component to change password for given user
 */
@Component({
  selector: 'app-user-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class ChangePasswordComponent implements OnInit  {

  /**
   * Password change form
   * @type {FormGroup}
   */
  public passwordChangeForm: FormGroup;

  /**
   * Timeout service
   * @type {TimeoutService}
   */
  public timeoutService: TimeoutService;

  /**
   * User
   * @type {User}
   */
  @Input() public user: User;

  /**
   * Password policy
   * @type {PasswordPolicy}
   */
  @Input() public passwordPolicy: PasswordPolicy;

  /**
   * Current (old) password required to change it?
   * @type {boolean}
   */
  @Input() public requireCurrentPassword: boolean =  false;

  /**
   * Event for when user password was changed. Emits changed user object
   * @type {EventEmitter}
   */
  @Output() onPasswordChanged: EventEmitter<User> = new EventEmitter();

  /**
   * Injects dependencies and initializes new timeout service
   */
  public constructor(
    protected formBuilder: FormBuilder,
    protected userService: UserService,
    protected passwordService: PasswordService,
    protected registryService: RegistryService,
    protected layoutService: LayoutService,
    protected loginService: LoginService,
    protected router: Router,
    @Optional() private translationService: TranslationService,
  ) {
    this.timeoutService = new TimeoutService();
  }

  /**
   * Initializes password change form
   */
  public ngOnInit() {
    const fb = this.formBuilder;

    const newPasswordValidators = [
      Validators.required,
      validators.passwordPolicyValidator('newPasswordRepeat')
    ];

    const newPasswordRepeatValidators = [
      Validators.required,
      validators.passwordsMatchValidator('newPassword')
    ];

    this.passwordChangeForm = fb.group({
      'oldPassword': fb.control(null, [Validators.required], [validators.oldPasswordValidator]),
      'newPassword': fb.control(null, newPasswordValidators),
      'newPasswordRepeat': fb.control(null, newPasswordRepeatValidators)
    });

    // add password policy to form. validators will use this
    this.passwordPolicy = this.passwordService.getPasswordPolicy();
    (<any>this.passwordChangeForm).passwordPolicy = this.passwordPolicy;

    // disable old/current password check if not required
    if (!this.requireCurrentPassword) {
      this.oldPassword.disable(true);
    }
  }

  /**
   * Will check if typed in old password matches current password. Check will only
   * run after the user did not enter anything into the input field for at least 0.5 seconds.
   * Sets an "oldPassword" object on the password change form. This is used by the the
   * async "passwordPolicyValidator" fn to determine, if the user typed in the correct
   * password!
   */
  public onResetOldPasswordCheckTimeout() {
    // no timeout running right now?
    if (!this.timeoutService.isRunning()) {
      // run fn after timeout
      this.timeoutService.after(500).run(() => {
        const oldPw = this.passwordChangeForm.value.oldPassword;
        const isCorrect = this.passwordService.isCurrentPassword(this.user, oldPw);
        (<any>this.passwordChangeForm).oldPassword = { isCorrect };
      });
    } else {
      // delete previous result (if any set)
      delete (<any>this.passwordChangeForm).oldPassword;

      // timeout already running? reset timeout!
      this.timeoutService.reset();
    }
  }

  /**
   * Event for when user wants to change the password
   */
  public onPasswordChange() {
    // get form value object
    const userValue: any = this.passwordChangeForm.value;

    // build user object to save
    const user: any = {
      userId: this.user.userId,
      password: userValue.newPassword
    };

    // save user => change user password
    this.userService
      .saveUser(<User>user)
      .then(savedUser => {
        // update salt and hash of user
        this.user.hash = savedUser.hash;
        this.user.salt = savedUser.salt;

        // reset form and trigger "pw changed" event
        this.passwordChangeForm.reset();
        this.onPasswordChanged.emit(this.user);

        alert(this._(KEYS.CRUD.SUCCESSFULLY_CHANGED_PASSWORD));

        // logout user when user was forced to change it
        if (this.registryService.get('forcePwChange')) {
          setTimeout(() => {
            this.registryService.set('forcePwChange', false);
            this.layoutService.showMenu();
            this.loginService.logout().then(() => {
              this.router.navigate(['module', 'login']).then().catch();
            }).catch();
          }, 2000);
        }
      })
      .catch(error => {
        console.log(error);
        alert(this._(KEYS.CRUD.ERROR_CHANGING_PASSWORD));
      });
  }

  /**
   * Returns "oldPassword" form control
   * @returns {any}
   */
  get oldPassword(): any {
    return this.passwordChangeForm.get('oldPassword');
  }

  /**
   * Returns "newPassword" form control
   * @returns {any}
   */
  get newPassword(): any {
    return this.passwordChangeForm.get('newPassword');
  }

  /**
   * Returns "newPasswordRepeat" form control
   * @returns {any}
   */
  get newPasswordRepeat(): any {
    return this.passwordChangeForm.get('newPasswordRepeat');
  }
}
