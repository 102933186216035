import {RouterModule, Routes} from '@angular/router';
import {UserGridComponent} from './components/user-grid/user-grid.component';
import {UserCrudComponent} from './components/user-crud/user-crud.component';
import {UserChangeComponent} from './components/user-change/user-change.component';
import {ResetPasswordComponent} from './components/reset-password/reset-password.component';
import {AuthTokenComponent} from './components/auth-token/auth-token.component';
import {permission} from './ums.permission';

const APP_ROUTES: Routes = [
  {
    path: 'module/ums/user/grid',
    component: UserGridComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.UMS_USER_READ,
        permission.UMS_USER_DELETE
      ]
    }
  },
  {
    path: 'module/ums/user/crud/new',
    component: UserCrudComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.UMS_USER_CREATE,
        permission.UMS_USER_UPDATE,
        permission.UMS_USER_ROLE_READ,
        permission.UMS_USER_ROLE_ASSIGN,
        permission.UMS_USER_ROLE_UNASSIGN,
        permission.UMS_TENANT_READ,
        permission.UMS_ROLE_READ
      ]
    }
  },
  {
    path: 'module/ums/user/crud/:id',
    component: UserCrudComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.UMS_USER_READ,
        permission.UMS_USER_UPDATE,
        permission.UMS_USER_ROLE_READ,
        permission.UMS_USER_ROLE_ASSIGN,
        permission.UMS_USER_ROLE_UNASSIGN,
        permission.UMS_TENANT_READ,
        permission.UMS_ROLE_READ
      ]
    }
  },
  {
    path: 'module/ums/auth-token',
    component: AuthTokenComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true,
      needsPermissions: [
        permission.UMS_USER_READ,
        permission.UMS_USER_UPDATE,
        permission.UMS_USER_ROLE_READ,
        permission.UMS_USER_ROLE_ASSIGN,
        permission.UMS_USER_ROLE_UNASSIGN,
        permission.UMS_TENANT_READ,
        permission.UMS_ROLE_READ
      ]
    }
  },
  {
    path: 'module/ums/user/change',
    component: UserChangeComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: true
    }
  },
  {
    path: 'module/ums/reset-password/:id/:email/:token/:locale',
    component: ResetPasswordComponent,
    canActivate: ['CoreGuard'],
    data: {
      needsLogin: false
    }
  }
];

export const routing = RouterModule.forRoot(APP_ROUTES);
