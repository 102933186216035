<div class="qa-reset-password">
  <div class="qa-reset-password floating-image animated fadeIn" #resetPwOverlay>
    <div class="container d-flex justify-content-center align-items-center" style="height: 60vh;width: 120px;" *ngIf="user">
      <div class="row">
        <div class="col-md-6">
          <div class="card rounded-2 animated fadeIn" #resetPwCard>
            <div class="card-header">
              <b>{{_(KEYS.CRUD.RESET_PASSWORD)}}</b>
            </div>
            <div class="card-body">
              <div class="alert alert-success" role="alert" style="width: 600px">
                <i class="fa fa-info-circle"></i>
                <span class="ml-2">
                  {{_(KEYS.CRUD.RESET_PASSWORD_HINT)}}
                </span>
              </div>
              <app-user-change-password
                [user]="user"
                [passwordPolicy]="passwordPolicy"
                [requireCurrentPassword]="false"
                (onPasswordChanged)="onPasswordChanged()">
              </app-user-change-password>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
