import {AfterViewInit, Component, OnDestroy, OnInit, Optional, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {FormBuilder, Validators, FormGroup} from '@angular/forms';

import {LayoutService} from '@ngmedax/layout';
import {LoginService} from '@ngmedax/login';
import {Translatable, TranslationService} from '@ngmedax/translation';

import {PasswordPolicy, User} from '../../../../types';
import {UmsService} from '../../services/ums.service';
import {UserService} from '../../services/user.service';
import {PasswordService} from '../../services/password.service';
import {passwordValidators} from '../change-password/password.validators';
import {TRANSLATION_CRUD_SCOPE} from '../../../../constants';
import {KEYS} from '../../../../translation-keys';
import {ConfigService} from "@ngmedax/config";
import {configKeys} from "../../ums.config-keys";
import {NgbNav} from "@ng-bootstrap/ng-bootstrap";
import {PasswordOptions} from "../../../../types";

// hack to inject decorator declarations. must occur before class declaration!
export interface UserCrudComponent extends Translatable {}

/**
 * Component to create and update user data and user assignments (filter groups, roles, etc)
 */
@Component({
  selector: 'app-user-crud',
  templateUrl: './user-crud.component.html',
  styleUrls: ['./user-crud.component.css'],
})
@Translatable({scope: TRANSLATION_CRUD_SCOPE, keys: KEYS})
export class UserCrudComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * ums tabs
   */
  @ViewChild('nav') umsTabs: NgbNav;

  /**
   * Subscriptions
   * @type {Subscription[]}
   */
  private subscriptions: Subscription[] = [];

  /**
   * User form
   * @type {FormGroup}
   */
  public userForm: FormGroup;

  /**
   * User
   * @type {User}
   */
  public user: User;

  /**
   * Password policy
   * @type {PasswordPolicy}
   */
  public passwordPolicy: PasswordPolicy;

  /**
   * Is the user required to type in the current password in order to change it?
   * @type {boolean}
   */
  public requireCurrentPassword: any = false;

  /**
   * Are we in preview mode?
   * @type {boolean}
   */
  public previewMode: boolean = false;

  /**
   * Filter group support ?
   */
  public hasFilterGroupSupport: boolean = true;

  /**
   * Injects dependencies
   */
  public constructor(
    @Optional() private translationService: TranslationService,
    @Optional() protected loginService: LoginService,
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    protected layoutService: LayoutService,
    protected umsService: UmsService,
    protected userService: UserService,
    protected passwordService: PasswordService,
    protected config: ConfigService)
  {
    this.hasFilterGroupSupport = !!this.config.get(configKeys.FILTER_GROUP_URI_CONFIG_KEY);
  }

  /**
   * Initializes preview mode, user form and route subscription for user id param
   */
  public ngOnInit() {
    this.previewMode = this.umsService.isPreviewMode();
    const fb = this.formBuilder;

    const pwValidators = [
      Validators.required,
      passwordValidators.passwordPolicyValidator('passwordRepeat')
    ];

    const pwRepeatValidators = [
      Validators.required,
      passwordValidators.passwordsMatchValidator('password')
    ];

    this.userForm = fb.group({
      'userId': fb.control(null),
      'email': fb.control(null, [Validators.required, Validators.email]),
      'password': fb.control(null, pwValidators),
      'passwordRepeat': fb.control(null, pwRepeatValidators),
      'gender': ['male', Validators.required],
      'title': fb.control(null),
      'firstName': fb.control(null, Validators.required),
      'lastName': fb.control(null, Validators.required),
      'street': fb.control(null),
      'zip': fb.control(null),
      'city': fb.control(null),
      'company': fb.control(null),
      'consultantProfile': fb.control(null)
    });

    // set password policy on user form. validators will need this
    this.passwordPolicy = this.passwordService.getPasswordPolicy();
    (<any>this.userForm).passwordPolicy = this.passwordPolicy;

    // subscribe to user id request param
    const routeSubscription = this.activatedRoute.params.subscribe(async (params: {id: string}) => {
      // early bailout if no user id in params
      if (!params.id) {
        return;
      }

      try {
        const user = await this.userService.loadUser(params.id);

        if (!user) {
          const id = params.id;
          alert(this._(KEYS.CRUD.FOUND_NO_USER_WITH_ID, {id}));
          return;
        }

        this.user = user;
        this.renderUser(user);
      } catch (error) {
        console.error(error);
        alert(this._(KEYS.CRUD.ERROR_LOADING_USER));
      }
    });

    this.subscriptions.push(routeSubscription);
  }

  public ngAfterViewInit() {
    // select password tab if url contains #password anchor
    window.location.href.split('#').pop() === 'password' &&
      setTimeout(() => this.umsTabs && this.umsTabs.select(2), 100);
  }

  /**
   * Unsubscribes from all subscriptions
   */
  public ngOnDestroy() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  /**
   * Form submit action. Saves user
   *
   * PLEASE NOTE: User crud will OVERRIDE user.data with it's own values.
   * It is therefore not possible to add other values to user.data object.
   * They must be part of the user crud form or we have to code an exception!!!
   */
  public onSubmit(callback?: Function) {
    // build user object template
    const user: any = {data: {}};

    // get user form values
    const userValue: any = this.userForm.value;

    // fields that are not part of the "user.data" object
    const baseFields = ['userId', 'customerId', 'email', 'password'];

    // iterate all user form keys
    for (const key of Object.keys(userValue)) {
      // skip if value of key is not set
      if (!userValue[key]) {
        continue;
      }

      const isDataField = (baseFields.indexOf(key) === -1);

      if (isDataField) {
        user.data[key] = userValue[key];
      } else {
        user[key] = userValue[key];
      }
    }

    // password repeat value must not be part of "user.data" object!
    if (user.data.passwordRepeat) {
      delete(user.data.passwordRepeat);
    }

    // save user
    this.userService
      .saveUser(<User>user)
      .then(savedUser => {
        // set user member by saved user and re-render user form
        this.user = savedUser;
        this.renderUser(savedUser);
        alert(this._(KEYS.CRUD.SUCCESSFULLY_SAVED_USER));
        callback && callback();
      })
      .catch(error => {
        console.log(error);
        if (typeof error === 'object'
          && typeof error.error === 'object'
          && error.error.message
          && error.error.message.match(/already created/i)
        ) {
          alert(this._(KEYS.CRUD.USER_ALREADY_EXISTS));
          return;
        }
        alert(this._(KEYS.CRUD.ERROR_SAVING_USER));
      });
  }

  public onInvite() {
    const pwLength = Math.floor(Math.random() * 16) + 12;
    const pw = this.generatePassword({length: pwLength, minUpper: 3, minLower: 3, minNumber: 3, minSpecial: 3});

    // set password in user form
    this.userForm.get('password').setValue(pw);
    this.userForm.get('passwordRepeat').setValue(pw);

    this.onSubmit(() => {
      const locale = this.translationService ? this.translationService.getLocale() : 'de_DE';
      this.userService.sendResetPwMail(this.user, locale).then(() => {
        alert(this._(KEYS.CRUD.USER_INVITE_SUCCESS));
      }).catch(error => {
        console.log(error);
        alert(this._(KEYS.CRUD.USER_INVITE_ERROR));
      });
    });
  }
  /**
   * Returns "email" form control
   * @returns {any}
   */
  get email(): any {
    return this.userForm.get('email');
  }

  /**
   * Returns "password" form control
   * @returns {any}
   */
  get password(): any {
    return this.userForm.get('password');
  }

  /**
   * Returns "passwordRepeat" form control
   * @returns {any}
   */
  get passwordRepeat(): any {
    return this.userForm.get('passwordRepeat');
  }

  /**
   * Renders user into form
   *
   * @param {User} user
   */
  protected renderUser(user: User) {
    this.userForm.reset();

    // not a new user? disable password fields!
    if (user.userId) {
      this.userForm.get('password').disable();
      this.userForm.get('passwordRepeat').disable();
    }

    // iterate all properties of user object
    for (const prop of Object.keys(user)) {
      // set matching form field if found by current property name
      if (this.userForm.contains(prop)) {
        this.userForm.get(prop).setValue(user[prop]);
      }
    }

    // set matching form fields for user data
    if (user.data) {
      for (const prop of Object.keys(user.data)) {
        if (this.userForm.contains(prop)) {
          this.userForm.get(prop).setValue(user.data[prop]);
        }
      }
    }
  }

  /**
   * Generates and returns password by given options
   *
   * @param {PasswordOptions} opts
   * @returns {string}
   */
  private generatePassword(opts?: PasswordOptions) {
    !opts && (opts = {});
    const length = opts.length || 8;
    const minUpper = opts.minUpper || 1;
    const minLower = opts.minLower || 0;
    const minNumber = opts.minNumber || -1;
    const minSpecial = opts.minSpecial || -1;

    const genArray = (length) => Array.from(Array(length).keys());

    let chars = String.fromCharCode(...genArray(127)).slice(33), //chars
      A2Z = String.fromCharCode(...genArray(91)).slice(65), //A-Z
      a2z = String.fromCharCode(...genArray(123)).slice(97), //a-z
      zero2nine = String.fromCharCode(...genArray(58)).slice(48), //0-9
      specials = chars.replace(/\w/g, ''); //special chars

    let minRequired = minSpecial + minUpper + minLower + minNumber;

    let rs = [].concat(
      Array.from({length: minSpecial ? minSpecial : 0}, () => specials[Math.floor(Math.random() * specials.length)]),
      Array.from({length: minUpper ? minUpper : 0}, () => A2Z[Math.floor(Math.random() * A2Z.length)]),
      Array.from({length: minLower ? minLower : 0}, () => a2z[Math.floor(Math.random() * a2z.length)]),
      Array.from({length: minNumber ? minNumber : 0}, () => zero2nine[Math.floor(Math.random() * zero2nine.length)]),
      Array.from({length: Math.max(length, minRequired) - (minRequired ? minRequired : 0)},
        () => a2z[Math.floor(Math.random() * a2z.length)])
    );

    rs.sort(() => Math.random() - 0.5);
    return rs
      .join('')
      .replace(/\\/, '')
      .replace(/\$/g, '!')
      .replace(/("|')/g, '^');
  }
}

