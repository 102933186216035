import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import { User } from '../../../types';

@Injectable()
export class UserTableExportService {

  public export(users: User[]): void {
    console.info(XLSX.SSF.get_table());
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    const workSheetData: any[] = [this.getHeader()];

    users.forEach(user => {
      const userData = user.data || {};
      const rowData: any[] = [
        { v: user.userId },
        { v: user.email },
        this.createDateCell(user.createdAt),
        { v: user.createdBy },
        this.createDateCell(user.lastChangeAt),
        { v: user.lastChangeBy },
        { v: user.tosAccepted ? 'Ja' : 'Nein' },
        this.createDateCell(user.acceptedAt),
        { v: userData.gender === 'male' ? 'Herr' : 'Frau' },
        { v: userData.firstName || '' },
        { v: userData.lastName || '' },
        { v: userData.street || '' },
        { v: userData.zip || '' },
        { v: userData.city || '' },
        { v: userData.company || '' },
        { v: userData.consultantProfile || '' },
      ];
      workSheetData.push(rowData);
    });

    const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(workSheetData, {cellDates: true});

    workSheet['!cols'] = [
      { wch: 10 }, // Benutzer ID
      { wch: 30 }, // Benutzername
      { wch: 15 }, // Erstellt am
      { wch: 25 }, // Erstellt durch
      { wch: 20 }, // Letzte Änderung am
      { wch: 25 }, // Letzte Änderung durch
      { wch: 15 }, // AGB akzeptiert
      { wch: 17 }, // AGB akzeptiert am
      { wch: 10 }, // Anrede
      { wch: 15 }, // Vorname
      { wch: 15 }, // Nachname
      { wch: 20 }, // Straße
      { wch: 10 }, // PLZ
      { wch: 15 }, // Stadt
      { wch: 20 }, // Unternehmen
      { wch: 20 }  // Beraterprofil
    ];

    XLSX.utils.book_append_sheet(workBook, workSheet, 'Users');
    XLSX.writeFile(workBook, 'users_export.xlsx');
  }

  private getHeader(): string[] {
    return [
      'Benutzer ID',
      'Benutzername             ',
      'Erstellt am   ',
      'Erstellt durch',
      'Letzte Änderung am',
      'Letzte Änderung durch',
      'AGB akzeptiert',
      'AGB akzeptiert am   ',
      'Anrede',
      'Vorname        ',
      'Nachname       ',
      'Straße             ',
      'PLZ',
      'Stadt          ',
      'Unternehmen    ',
      'Beraterprofil'
    ];
  }

  private createDateCell(jsonDate: string | undefined): any {
    if (!jsonDate) return { v: '', t: 's' };
    const date = new Date(jsonDate);
    return { v: date, t: 'd', z: XLSX.SSF.get_table()[60] };
  }
}
