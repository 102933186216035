import {EventEmitter, Inject, Injectable} from '@angular/core';

import * as merge from 'deepmerge';
import {HttpClient} from '@angular/common/http';


@Injectable()
/**
 * Configuration service
 * Loads a json configuration file via the "load" method
 * and allows access to config values by using method "get"
 */
export class ConfigService {
  private config: object = {};
  private loaded = false;
  private afterLoad: EventEmitter<void> = new EventEmitter<void>();

  /**
   * Injects dependencies
   *
   * @param http
   * @param baseConfig
   */
  public constructor(
    private http: HttpClient,
    @Inject('BASE_CONFIG') private baseConfig,
    @Inject('MODIFIER_FN') private modifier
  ) {
  }

  /**
   * Loads a config file via a http get request by given config file uri.
   * Returns a promise that resolves when the config file was loaded.
   *
   * @param configFile
   * @returns {Promise<any>}
   */
  public load(configFile) {
    return new Promise<void>((resolve) => {
      this.http.get(configFile)
        .subscribe(config => {
          this.config = merge(this.baseConfig, config);
          this.modify(this.modifier);
          this.resolveKeys();
          this.loaded = true;
          this.onAfterLoad().emit();
          resolve();
        });
    });
  }

  /**
   * Returns true if the config is loaded
   *
   * @returns {boolean}
   */
  public isLoaded(): boolean {
    return this.loaded;
  }

  /**
   * Returns event emitter for "on after load" event
   *
   * @returns {EventEmitter<void>}
   */
  public onAfterLoad(): EventEmitter<void> {
    return this.afterLoad;
  }

  /**
   * Returns a config param by given config key. You can use "." to get deep values.
   * E.g:
   *   // returns the value "Hello" in {"foo": {"bar": "Hello" }}
   *   this.get('foo.bar')
   *
   * @param {string} configKey
   * @returns {any}
   */
  public get(configKey: string): any {
    let value = this.getByActiveDomain(configKey);

    if (value === null) {
      value = this.getByConfigKey(configKey.toLowerCase());
    }

    if (value === null) {
      value = this.getByConfigKey(configKey);
    }

    return value;
  }

  /**
   * Modifies config by given function
   *
   * @param {(config: any) => void} fn
   */
  public modify(fn: (config: any) => void) {
    (typeof fn === 'function') && fn(this.config);
  }

  /**
   * Tries to get value by prepending active domain to config key.
   * This way domain config will always supersede normal config
   *
   * @param configKey
   * @returns {any}
   */
  private getByActiveDomain(configKey): any {
    if (window && window.location) {
      // try with port, then without
      for (const domain of [window.location.host, window.location.hostname]) {
        const domainConfigKey = `domains.${domain}.${configKey}`;

        // try lowercase, then without
        let value = this.getByConfigKey(domainConfigKey.toLowerCase()) || this.getByConfigKey(domainConfigKey);

        if (value) {
          return value;
        }
      }

      return this.getByConfigKey(configKey);
    }
  }

  /**
   * Returns a config param by given config key. You can use "." to get deep values.
   *
   * E.g:
   *   // returns the value "Hello" in {"foo": {"bar": "Hello" }}
   *   this.get('foo.bar');
   *
   * You can also use "." in a property name instead.
   *
   * E.g:
   *   // returns the value "{"foo": "bar"}" in {"some.tld": {"foo":"bar"}}
   *   this.get('some.tld');
   *
   * @param {string} configKey
   * @returns {any}
   */
  private getByConfigKey(configKey: string): any {
    // get config
    let config = this.config;

    // split config key via dot
    const configKeyArr = configKey.split('.');

    // iterate each config key
    for (let i = 0; i < configKeyArr.length; i++) {
      // get the current config key. replace | with . for when we are searching for flat keys
      const currentConfigKey = configKeyArr[i].replace(/[|]/g, '.');

      // if current config key is present in configuration
      if (typeof config[currentConfigKey] !== 'undefined') {
        // go to next dept of config
        config = config[currentConfigKey];
      } else {
        // get next config key in array
        const next = configKeyArr[i + 1];

        // if we have a next config key
        if (next) {
          // try to replace . with | for flat keys
          configKey = configKey.replace(
            configKeyArr[i] + '\.' + next,
            currentConfigKey + '|' + next
          );
          // try to get the config key again with a flat key
          return this.getByConfigKey(configKey);
        }

        return null;
      }
    }

    return this.resolveValues(config);
  }

  /**
   * Recursively resolves and replaces inline keys in values with their respective config key value.
   * An inline key is a usage of a config key in a value. Special notation for this is {{configKey}}.
   * Config keys "." notation must be replaced with a "#". So in order to resolve "foo.bar" you have to add {{foo#bar}}
   *
   * E.g:
   * // in order to inline replace "apis.baseUri", add it as {{apis#baseUri}}.
   * config = {
   *   "apis": {
   *     "baseUri": "http://some.tld",
   *     "login": {
   *       "uri": "{{apis#baseUri}}/login"
   *     }
   *   }
   * }
   *
   * // returns "http://some.tld/login"
   * configService.get('apis.login.uri');
   *
   * @param configValue
   * @returns {any}
   */
  private resolveValues(configValue: any) {
    const isString = typeof configValue === 'string';
    const isArray = Array.isArray(configValue);
    const isObject = !isArray && typeof configValue === 'object' && configValue !== null;

    if (isString) {
      const inlineKeys = configValue.match(/{{.*?}}/g);

      if (!inlineKeys) {
        return configValue;
      }

      configValue = this.getValueByInlineKeys(inlineKeys, configValue);
    }

    if (isArray) {
      for (let i = 0; i < configValue.length; i++) {
        configValue[i] = this.resolveValues(configValue[i]);
      }
    }

    if (isObject) {
      for (const key of Object.keys(configValue)) {
        configValue[key] = this.resolveValues(configValue[key]);
      }
    }

    return configValue;
  }

  /**
   * Resolves all config keys with variables (aka: inline keys) by their value
   *
   * e.g:
   * config = {
   *   "env": {
   *     "FOO": 'myFoo"
   *   }
   *   "{{env#FOO}}": {
   *     "bar": "baz",
   *   }
   * }
   *
   * resolves to:
   * config = {
   *   "env": {
   *     "FOO": 'myFoo"
   *   }
   *   "myFoo": {
   *     "bar": "baz",
   *   }
   * }
   */
  private resolveKeys() {
    const walk = (config: any) => {
      const isArray = Array.isArray(config);
      const isObject = !isArray && typeof config === 'object' && config !== null;

      if (isObject) {
        for (const key of Object.keys(config)) {
          const inlineKeys = key.match(/{{.*?}}/g);

          if (inlineKeys) {
            const newKey = this.getValueByInlineKeys(inlineKeys, key);
            newKey && (typeof newKey === 'string') && (config[newKey] = config[key]);
            (newKey !== key) && delete(config[key]);
            walk(config[newKey]);
          } else {
            walk(config[key]);
          }
        }
      }
    };

    walk(this.config);
  }

  /**
   * Resolves given inline keys array to values, concatenates and returns them
   *
   * @param {string[]} inlineKeys
   * @param {string} replaceValue
   * @returns {string}
   */
  private getValueByInlineKeys(inlineKeys: string[], replaceValue: string) {
    for (const inlineKey of inlineKeys) {
      const configKey = inlineKey
        .replace(/^{{/, '')
        .replace(/}}$/, '')
        .replace(/#/g, '.');

      if (configKey === 'location.hostname') {
        return replaceValue.replace(inlineKey, location.hostname);
      }

      if (configKey === 'location.host') {
        return replaceValue.replace(inlineKey, location.host);
      }

      replaceValue = replaceValue.replace(inlineKey, this.get(configKey) || '');
    }

    return replaceValue;
  }
}
