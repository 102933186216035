export const KEYS = {
  GRID: {
    ERROR_LOADING_USERS: 'Beim Laden der Benutzer ist ein Fehler aufgetreten!',
    QUESTION_DELETE_USER: 'Folgenden Benutzer wirklich löschen?: [[username]]',
    SUCCESSFULLY_DELETED_USER: 'Der Benutzer wurde erfolgreich gelöscht.',
    ERROR_DELETING_USER: 'Beim Löschen des Benutzers ist ein Fehler aufgetreten.',
    USER_RESET_PW_MAIL_SUCCESS: 'Eine E-Mail zum Zurücksetzen des Passworts wurde an den Benutzer gesendet.',
    USER_RESET_PW_MAIL_ERROR: 'Beim Versenden der E-Mail zum Zurücksetzen des Passworts ist ein Fehler aufgetreten.',
    USER_RESET_PW_QUESTION: 'E-Mail zum Zurücksetzen des Passworts senden?',
    RESET_PASSWORD: 'Passwort zurücksetzen',
    USERS: 'Benutzer',
    SEARCH: 'Suche',
    USERNAME: 'Benutzername',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    COMPANY: 'Firma',

    ID: 'Id',
    USERNAME_MAIL: 'Benutzer / E-Mail',
    NAME: 'Name',
    ADDRESS: 'Adresse',
    UPDATED_AT: 'Bearbeitet am',
    UPDATED_BY: 'Bearbeitet von',
    OPTIONS: 'Optionen',
    ENTRIES: 'Einträge',
    CHANGE: 'Ändern',
    DELETE: 'Löschen'
  },
  CRUD: {
    FOUND_NO_USER_WITH_ID: 'Es wurde kein Benutzer mit folgender Id gefunden: [[id]]',
    ERROR_LOADING_USER: 'Beim Laden der Benutzers ist ein Fehler aufgetreten.',
    ERROR_SAVING_USER: 'Beim Speichern des Benutzers ist ein Fehler aufgetreten.',
    SUCCESSFULLY_SAVED_USER: 'Der Benutzer wurde erfolgreich gespeichert.',
    USER: 'Benutzer',
    USER_DATA: 'Benutzerdaten',
    USERNAME: 'Benutzername',
    PASSWORD: 'Passwort',
    REPEAT_PASSWORD: 'Passwort wiederholen',
    SALUTATION: 'Anrede',
    SALUTATION_MR: 'Herr',
    SALUTATION_MS: 'Frau',
    TITLE: 'Titel',
    EG_PROF_DR: 'Bsp: Prof. Dr.',
    FIRSTNAME: 'Vorname',
    LASTNAME: 'Nachname',
    STREET: 'Straße',
    ZIP_CODE: 'PLZ',
    CITY: 'Stadt',
    ENTER_USERNAME: 'Bitte Benutzername eingeben...',
    ENTER_PASSWORD: 'Bitte Passwort eingeben...',
    ENTER_EMAIL: 'Bitte geben Sie eine valide E-Mail-Adresse ein.',
    ENTER_FIRSTNAME: 'Bitte Vorname eingeben...',
    ENTER_LASTNAME: 'Bitte Nachname eingeben...',
    ENTER_STREET: 'Bitte Straße eingeben...',
    ENTER_ZIP_CODE: 'Bitte PLZ eingeben...',
    ENTER_CITY: 'Bitte Stadt eingeben...',
    ENTERED_PASSWORDS_NO_MATCH: 'Die eingegebenen Passwörter stimmen nicht überein.',
    SAVE: 'Speichern',
    CHANGE_PASSWORD: 'Passwort ändern',
    FILTER_GROUPS: 'Fachgruppen',
    PERMISSIONS: 'Rechte',
    USER_PERMISSIONS: 'Benutzerrechte',
    SUCCESSFULLY_CHANGED_PASSWORD: 'Das Passwort wurde erfolgreich geändert.',
    ERROR_CHANGING_PASSWORD: 'Beim Ändern des Passworts ist ein Fehler aufgetreten.',
    CURRENT_PASSWORD: 'Aktuelles Passwort',
    NEW_PASSWORD: 'Neues Passwort',
    ENTER_CURRENT_PASSWORD: 'Bitte aktuelles Passwort eingeben...',
    ENTER_NEW_PASSWORD: 'Bitte neues Passwort eingeben...',
    REPEAT_NEW_PASSWORD: 'Neues Passwort wiederholen',
    INVALID_PASSWORD: 'Das eingegebene Passwort ist nicht korrekt.',
    USER_SERVICE_NOT_AVAILABLE: 'Der eingeloggte Benutzer kann nicht ermittelt werden, da der Login Service nicht verfügbar ist.',
    UNABLE_TO_FIND_USER_BY_ID: 'Der eingeloggte Benutzer wurde nicht gefunden. Benutzer-Id unbekannt: [[id]]',
    ERROR_LOADING_FILTER_GROUPS: 'Beim Laden der Fachgruppen ist ein Fehler aufgetreten.',
    ERROR_SETTING_FILTER_GROUP: 'Beim Setzen der Fachgruppe ist ein Fehler aufgetreten.',
    FILTER_GROUP_ID: 'Id',
    FILTER_GROUP_DESCRIPTION: 'Bezeichnung',
    ASSIGN: 'Zuweisen',
    ASSIGNED: 'Zugewiesen',
    ALL: 'Alle',
    ALL_TENANTS: 'Alle Mandanten',
    ERROR_LOADING_TENANT_OR_ROLES: 'Beim Laden der Mandanten/Rollen ist ein Fehler aufgetreten.',
    ERROR_SETTING_ROLE: 'Beim Setzen der Rolle ist ein Fehler aufgetreten.',
    TENANT: 'Mandant',
    ROLES: 'Rollen',
    ROLE: 'Rolle',
    DESCRIPTION: 'Beschreibung',
    RESET_PASSWORD: 'Passwort zurücksetzen',
    RESET_PASSWORD_HINT: 'Bitte geben Sie Ihr neues Passwort ein. Nachdem Sie das Formular abgesendet haben, wird Ihr Passwort aktualisiert.',
    USER_ALREADY_EXISTS: 'Der Benutzer kann nicht angelegt werden, da er bereits existiert.',
    USER_INVITE_SUCCESS: 'Eine Einladungsmail wurde an den Benutzer gesendet.',
    USER_INVITE_ERROR: 'Beim Versenden der Einladungsmail ist ein Fehler aufgetreten.',
    INVITE_USER: 'Benutzer einladen',
    CONSULTANT_PROFILE: 'Beraterprofil',
    COMPANY: 'Firma',
  },
  AUTH_TOKEN: {
    ERROR_CHECKING_AUTH_TOKEN: 'Beim prüfen des Tokens ist ein Fehler aufgetreten.',
    AUTH_TOKEN: 'Authentifizierungstoken',
    USERNAME: 'Benutzername',
    ENTER_USERNAME: 'Bitte Benutzername eingeben...',
    ENTER_EMAIL: 'Bitte geben Sie eine valide E-Mail-Adresse ein.',
    PASSWORD: 'Passwort',
    ENTER_PASSWORD: 'Bitte Passwort eingeben...',
    SHARED_SECRET: 'Shared Secret',
    ENTER_SHARED_SECRET: 'Bitte Shared Secret eingeben...',
    CREATE_TOKEN: 'Token erstellen',
    INVALID_AUTH_TOKEN: 'Das generierte Token ist invalide. Bitte prüfen Sie die eingegebenen Daten auf Richtigkeit.'
  },
  TABLE_EXPORT: {
    TABLE_EXPORT: 'Tabellen Export',
    CREATED_AT: 'Erstellt am',
    FROM: 'Von',
    TO: 'Bis',
    EXPORT: 'Export',
    YES: 'Ja',
    NO: 'Nein'
  }
};
